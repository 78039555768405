<template>
  <section id="dashboard-analytics">
    <b-row v-if="analysesInfo">
      <b-col cols="12">
        <b-card no-body>
          <div class="m-2">
            <b-row>
              <b-col md="12">
                <b-form-group label="اسم التحليل" class="required">
                  <b-form-input
                    v-model="analysesInfo.title"
                    placeholder="ادخل اسم التحليل"
                  />
                </b-form-group>
              </b-col>

              <b-col md="12" class="mt-2 d-flex justify-content-between">
                <button onclick="history.back()" class="filter-main-btn">
                  إلـــــــــغــــــــاء
                </button>
                <button
                  :disabled="analysesInfo.price == ''"
                  class="add-main-btn"
                  @click="updateAnalysesFun"
                >
                  حـــــفــــظ التغييرات
                </button>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import vSelect from "vue-select";
import draggable from "vuedraggable";
import _ from "lodash";
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BTooltip,
  BInputGroup,
  BInputGroupPrepend,
  BInputGroupAppend,
  BFormGroup,
  BFormFile,
  BFormTextarea,
  BFormCheckbox,
} from "bootstrap-vue";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BTooltip,
    vSelect,
    BInputGroup,
    BFormGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormFile,
    BFormTextarea,
    BFormCheckbox,
    draggable,
  },
  data() {
    return {
      analysesInfo: {
        id: "",
        title: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      getAnalysesDetails: "getAnalysesDetails",
    }),
  },
  watch: {
    getAnalysesDetails(data) {
      if (data) {
        this.analysesInfo = data;
        this.analysesInfo.title = data.title;
      }
    },
  },
  created() {},
  methods: {
    ...mapActions({
      loadAnalysesDetails: "loadAnalysesDetails",
      editAnalyses: "editAnalyses",
    }),
    updateAnalysesFun() {
      this.editAnalyses({
        id: this.$router.currentRoute.params.id,
        title: this.analysesInfo.title,
      }).then((res) => {
        this.$router.push({
          name: "settings-analyzes",
        });
      });
    },
  },
  mounted() {
    this.loadAnalysesDetails(this.$router.currentRoute.params.id);
  },
};
</script>
